import React from 'react';
import { Link, graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { rhythm, scale } from '../utils/typography';

class ChapterTemplate extends React.Component {
  render() {
    const chap = this.props.data.markdownRemark;
    const siteTitle = this.props.data.site.siteMetadata.title;
    const { previous, next } = this.props.pageContext;

    let tocHtml = chap.tableOfContents.replace(/&#x3C;/gi, '<'); // HACK

    return (
      <Layout
        location={this.props.location}
        title={siteTitle}
        prev={previous}
        next={next}
      >
        <SEO
          title={chap.frontmatter.title}
          description={chap.frontmatter.description || chap.excerpt}
        />
        <h1 className="chap-title">{chap.frontmatter.title}</h1>
        {chap.frontmatter.hideToc ? null : (
          <div className="chap-toc">
            <div>contents</div>
            <div dangerouslySetInnerHTML={{ __html: tocHtml }} />
          </div>
        )}
        <div
          className={'chap-content ' + chap.frontmatter.slug}
          dangerouslySetInnerHTML={{ __html: chap.html }}
        />
        <div className="chap-end" />
      </Layout>
    );
  }
}

export default ChapterTemplate;

export const pageQuery = graphql`
  query ChapterBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      fields {
        slug
      }
      frontmatter {
        title
        description
        hideToc
        slug
      }
      tableOfContents(pathToSlugField: "frontmatter.slug")
    }
  }
`;
